<template>
  <div class="count-down">
    <van-button
      color="#13b5b1"
      size="small"
      v-if="highlight"
      @click="startDown" 
      >{{ $t("sendVerificationCode") }}</van-button
    >
    <van-button color="#13b5b1" size="small" disabled v-else>
      <template #default>
        <van-cell-group :border="false" class="count-down-group">
          <van-loading size="20" />
          <van-count-down
            ref="countDown"
            millisecond
            :time="1000 * 90"
            :auto-start="false"
            format="ss"
            @finish="finish"
          />
        </van-cell-group>
      </template>
    </van-button>
  </div>
</template>

<script>
import { Toast } from "vant";
export default {
  data() {
    return {
      highlight: true,
    };
  },
  methods: {
    start() {
      this.$refs.countDown.start();
    },
    pause() {
      this.$refs.countDown.pause();
    },
    reset() {
      this.$refs.countDown.reset();
    },
    finish() {
      this.highlight = true;
    },
    startDown() {
      this.highlight = false;
      this.$nextTick(() => {
        this.start();
      });
    },
  },
};
</script>

<style scoped>
.van-button {
  border-radius: 5px;
}
.count-down-group {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: transparent;
}
.van-count-down {
  color: #eee;
  margin-left: 12px;
}
</style>